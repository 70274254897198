<template>
  <HhModal id="register-modal" v-model="showModal">
    <div class="shrink p-6 sm:p-12" style="width: 500px">
      <img
        src="~/assets/image/logo-new-full-alternate.png"
        width="80"
        loading="lazy"
        alt="Hungry hub Logo"
        class="mx-auto"
      />
      <h1 class="mb-4 mt-8 text-center text-xl font-bold capitalize">
        {{ t("register") }}
      </h1>

      <form>
        <div
          class="input-with-icon have-icon-left mb-3 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
        >
          <span class="icon icon-left mr-2 flex items-center text-lg">
            <IconUserCircle class="h-6 w-6 text-red-dark" />
          </span>
          <HhInput
            v-model="registerName"
            name="name"
            autocomplete="name"
            class="input flex flex-auto border-x-0 border-t-0 border-none text-sm outline-none lg:text-base"
            :placeholder="t('name')"
            type="text"
            :disabled="isRegisterDisabled"
          />
        </div>
        <div
          class="input-with-icon have-icon-left mb-3 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
        >
          <span class="icon icon-left mr-2 flex items-center text-lg">
            <IconPhone class="h-5 w-5 text-red-dark" />
          </span>
          <HhInput
            id="phone-input"
            v-model="registerPhone"
            name="phone"
            autocomplete="tel-local"
            class="input flex flex-auto border-x-0 border-t-0 border-none text-sm outline-none lg:text-base"
            :placeholder="t('phone')"
            type="text"
            :disabled="isRegisterDisabled"
          />
        </div>
        <div
          class="input-with-icon have-icon-left mb-3 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
        >
          <span class="icon icon-left mr-2 flex items-center text-lg">
            <IcOutlineEmail class="text-red-dark" />
          </span>
          <HhInput
            v-model="registerEmail"
            name="email"
            autocomplete="email"
            class="input flex flex-auto border-x-0 border-t-0 border-none text-sm outline-none lg:text-base"
            :placeholder="t('email')"
            type="text"
            :disabled="isRegisterDisabled"
          />
        </div>
        <div
          class="input-with-icon have-icon-left mb-2 flex w-full items-center border-b border-gray-500 py-2 lg:mb-3"
        >
          <span class="icon icon-left mr-2 flex items-center text-lg">
            <IcBaselineLock class="text-red-dark" />
          </span>
          <HhInput
            v-model="registerPassword"
            name="password"
            autocomplete="current-password"
            class="input flex flex-auto border-x-0 border-t-0 border-none text-sm outline-none lg:text-base"
            :placeholder="t('password')"
            :type="showPassword ? 'text' : 'password'"
            :disabled="isRegisterDisabled"
          />

          <button
            id="show-password-button"
            type="button"
            class="icon icon-right mr-2 flex items-center text-lg"
            @click="showPassword = !showPassword"
          >
            <MdiEyeOutline v-show="showPassword" />
            <MdiEyeOffOutline v-show="!showPassword" />
          </button>
        </div>
        <div class="flex items-start">
          <input
            id="checkbox-subscribe"
            v-model="isPreferSubscribe"
            type="checkbox"
            class="red-checkbox mr-2 mt-1"
          />
          <label
            for="checkbox-subscribe"
            class="text-sm"
            v-html="
              t('subscribeNewsLetter', { amount: subscribeMarketingReward })
            "
          ></label>
        </div>
        <div class="w-full text-center">
          <HhButton
            id="register-submit-button"
            type="button"
            class="mt-6 h-10 w-full lg:mt-8 lg:w-2/3"
            rounded
            :disabled="isLoading || isRegisterDisabled"
            @click="register"
          >
            <div class="font-bold uppercase">
              {{ isLoading ? t("loading") : t("register") }}
            </div>
          </HhButton>
        </div>
        <div class="mt-2 text-center text-sm">
          {{ t("alreadyMember") }} ?
          <a
            class="font-black capitalize text-red-dark"
            href=""
            @click.prevent="showSignInModal"
            >{{ t("signIn") }}</a
          >
        </div>
        <div
          class="mt-6 text-center text-sm"
          v-html="
            t('registerTermCondition', {
              tncLink: tncLink,
              privacyPolicyLink: privacyPolicyLink,
            })
          "
        ></div>
      </form>
    </div>
  </HhModal>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useModal } from "~/lib/modal";
import HhModal from "~/components/HhModal.vue";
import HhInput from "~/components/HhInput.vue";
import IcOutlineEmail from "~icons/ic/outline-email";
import IcBaselineLock from "~icons/ic/baseline-lock";
import MdiEyeOutline from "~icons/mdi/eye-outline";
import MdiEyeOffOutline from "~icons/mdi/eye-off-outline";
import IconUserCircle from "~icons/hh-icons/icon-user-circle";
import IconPhone from "~icons/hh-icons/icon-phone";
import useConfigStore from "~/stores/config";
import alert from "~/lib/alert";
import { useIntlTelInput } from "~/lib/intlTelInput";

const hhModal = useModal();

const { t } = useI18n({
  messages: {
    en: {
      signIn: "Sign In",
      loading: "Please wait ...",
      notAmember: "Not a member ?",
      register: "Register",
      forgotPassword: "Forgot password?",
      name: "Name",
      phone: "Phone",
      email: "Email",
      password: "Password",
      alreadyMember: "Already Member",
      registerTermCondition:
        "By signing in or creating an account, you agree with our <a class='text-blue-500 hover:underline' href='{tncLink}' target='_blank'>General Terms & Conditions</a>  and <a class='text-blue-500 hover:underline' href='{privacyPolicyLink}' target='_blank'>Privacy Policy</a>",
    },
    th: {
      signIn: "เข้าสู่ระบบ",
      loading: "โปรดรอสักครู่ ...",
      notAmember: " ผู้ใช้ใหม่?",
      register: "ลงทะเบียน",
      forgotPassword: "ลืมรหัสผ่าน?",
      name: "ชื่อ",
      phone: "เบอร์โทร",
      email: "อีเมล",
      password: "พาสเวิร์ด",
      alreadyMember: "สมาชิกของเราเข้าระบบที่นี่",
      registerTermCondition:
        "การลงชื่อเข้าใช้หรือสร้างบัญชีสมาชิก หมายถึงคุณยอมรับ <a class='text-blue-500 hover:underline' href='{tncLink}' target='_blank'>ข้อกำหนดและเงื่อนไขในการใช้งาน</a> และ <a class='text-blue-500 hover:underline' href='{privacyPolicyLink}' target='_blank'>นโยบายความเป็นส่วนตัว</a>",
    },
  },
});
const { lang, subscribeMarketingReward } = storeToRefs(useConfigStore());

const showModal = ref(false);
const isLoading = ref(false);
const registerName = ref("");
const registerPhone = ref("");
const registerEmail = ref("");
const registerPassword = ref("");
const registerPhoneCode = ref("+66");
const isPreferSubscribe = ref(false);
const showPassword = ref(false);
const isRegisterDisabled = ref(false);
const tncLink =
  "https://hungryhub.zendesk.com/hc/en-us/articles/360009040134-General-T-C-%E0%B8%82-%E0%B8%AD%E0%B8%81%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B8%94%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%87%E0%B8%B7-%E0%B8%AD%E0%B8%99%E0%B9%84%E0%B8%82";
const privacyPolicyLink =
  "https://hungryhub.zendesk.com/hc/en-us/articles/360009040074";
const intlTelInputLib = await useIntlTelInput();

const showSignInModal = () => {
  hhModal.open("signin-modal");
};

const register = async () => {
  try {
    isLoading.value = true;
    const { doSignUp } = await import("~/services/auth/signUp");
    const useUserStore = (await import("~/stores/user")).default;
    const userStore = useUserStore();

    const { isSuccess, message } = await doSignUp({
      lang: lang.value === "th" ? "th-TH" : "en-EN",
      name: registerName.value,
      phone: registerPhone.value,
      email: registerEmail.value,
      password: registerPassword.value,
      phoneCode: registerPhoneCode.value,
      referralCode: "",
      guestBookingIds: userStore.guestBookingIds,
      subscribePromotion: isPreferSubscribe.value,
    });
    if (isSuccess) {
      registerSuccessCallback();
    } else if (message) {
      alert.error(message);
    }
  } catch (err: any) {
    alert.error(err.message);
  } finally {
    isLoading.value = false;
  }
};

const registerSuccessCallback = async () => {
  try {
    isLoading.value = true;
    const { signInWithEmail } = await import("~/services/auth/signInWithEmail");
    const useUserStore = (await import("~/stores/user")).default;
    const userStore = useUserStore();
    const { isSuccess, message } = await signInWithEmail({
      email: registerEmail.value,
      password: registerPassword.value,
    });
    if (isSuccess) {
      alert.success(`Welcome ${userStore.name}`);
    } else if (message) {
      alert.error(message);
    }
  } catch (err: any) {
    alert.error(err.message);
  } finally {
    isLoading.value = false;
  }
};

let timeOutInstance: any;

const initInputTelephone = () => {
  try {
    const el = document.getElementById("phone-input");
    if (!el) {
      throw new Error("Missing phone-input element");
    }
    const advInputTel = intlTelInputLib(el, {
      initialCountry: "th",
      separateDialCode: false,
    });
    if (el) {
      el.style.cssText = "padding-left: 45px";

      el.addEventListener(
        "countrychange",
        () => {
          registerPhoneCode.value = `+${
            advInputTel.getSelectedCountryData().dialCode
          }`;
        },
        { passive: true }
      );
    }
  } catch (err: any) {
    alert.error(err);
  }
};

onUnmounted(() => {
  clearTimeout(timeOutInstance);
});
onMounted(() => {
  initInputTelephone();
});
</script>
